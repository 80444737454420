// Operator Icon Imports
import AceIcon from './ace.png';
import AlibiIcon from './alibi.png';
import AttackerIcon from './attacker.png';
import AmaruIcon from './amaru.png';
import AruniIcon from './aruni.png';
import AshIcon from './ash.png';
import AzamiIcon from './azami.png';
import BanditIcon from './bandit.png';
import BlackbeardIcon from './blackbeard.png';
import BlitzIcon from './blitz.png';
import BravaIcon from './brava.png';
import BuckIcon from './buck.png';
import CapitaoIcon from './capitao.png';
import CastleIcon from './castle.png';
import CaveiraIcon from './caveira.png';
import ClashIcon from './clash.png';
import DefenderIcon from './defender.png';
import DeimosIcon from './deimos.png';
import DocIcon from './doc.png';
import DokkaebiIcon from './dokkaebi.png';
import EchoIcon from './echo.png';
import ElaIcon from './ela.png';
import FenrirIcon from './fenrir.png';
import FinkaIcon from './finka.png';
import FloresIcon from './flores.png';
import FrostIcon from './frost.png';
import FuzeIcon from './fuze.png';
import GlazIcon from './glaz.png';
import GoyoIcon from './goyo.png';
import GridlockIcon from './gridlock.png';
import GrimIcon from './grim.png';
import HibanaIcon from './hibana.png';
import IanaIcon from './iana.png';
import IqIcon from './iq.png';
import JackalIcon from './jackal.png';
import JagerIcon from './jager.png';
import KaidIcon from './kaid.png';
import KaliIcon from './kali.png';
import KapkanIcon from './kapkan.png';
import LesionIcon from './lesion.png';
import LionIcon from './lion.png';
import MaestroIcon from './maestro.png';
import MaverickIcon from './maverick.png';
import MelusiIcon from './melusi.png';
import MiraIcon from './mira.png';
import MontagneIcon from './montagne.png';
import MozzieIcon from './mozzie.png';
import MuteIcon from './mute.png';
import NokkIcon from './nokk.png';
import NomadIcon from './nomad.png';
import OryxIcon from './oryx.png';
import OsaIcon from './osa.png';
import PulseIcon from './pulse.png';
import BlueRecruitIcon from './recruit_blue.png';
import GreenRecruitIcon from './recruit_green.png';
import OrangeRecruitIcon from './recruit_orange.png';
import RedRecruitIcon from './recruit_red.png';
import YellowRecruitIcon from './recruit_yellow.png';
import RamIcon from './ram.png';
import RookIcon from './rook.png';
import SensIcon from './sens.png';
import SledgeIcon from './sledge.png';
import SmokeIcon from './smoke.png';
import SolisIcon from './solis.png';
import TachankaIcon from './tachanka.png';
import ThatcherIcon from './thatcher.png';
import ThermiteIcon from './thermite.png';
import ThornIcon from './thorn.png';
import ThunderbirdIcon from './thunderbird.png';
import TubaraoIcon from './tubarao.png';
import TwitchIcon from './twitch.png';
import ValkyrieIcon from './valkyrie.png';
import VigilIcon from './vigil.png';
import WamaiIcon from './wamai.png';
import WardenIcon from './warden.png';
import YingIcon from './ying.png';
import ZeroIcon from './zero.png';
import ZofiaIcon from './zofia.png';
import StrikerIcon from './striker.png';
import SentryIcon from './sentry.png';
import SkoposIcon from './skoposIcon.png';

// Primary Utility Icon Imports
import AceGadget from './aceGadget.png';
import AlibiGadget from './alibiGadget.png';
import AmaruGadget from './amaruGadget.png';
import AruniGadget from './aruniGadget.png';
import AshGadget from './ashGadget.png';
import AzamiGadget from './azamiGadget.png';
import BanditGadget from './banditGadget.png';
import BlackbeardGadget from './blackbeardGadget.png';
import BlitzGadget from './blitzGadget.png';
import BravaGadget from './bravaGadget.png';
import BuckGadget from './buckGadget.png';
import CapitaoFireGadget from './capitaoFireGadget.png';
import CapitaoSmokeGadget from './capitaoSmokeGadget.png';
import CastleGadget from './castleGadget.png';
import CaveiraGadget from './caveiraGadget.png';
import ClashGadget from './clashGadget.png';
import DeimosGadget from './deimosGadget.png';
import DocGadget from './docGadget.png';
import DokkaebiGadget from './dokkaebiGadget.png';
import EchoGadget from './echoGadget.png';
import ElaGadget from './elaGadget.png';
import FenrirGadget from './fenrirGadget.png';
import FinkaGadget from './finkaGadget.png';
import FloresGadget from './floresGadget.png';
import FrostGadget from './frostGadget.png';
import FuzeGadget from './fuzeGadget.png';
import GlazGadget from './glazGadget.png';
import GoyoGadget from './goyoGadget.png';
import GridlockGadget from './gridlockGadget.png';
import GrimGadget from './grimGadget.png';
import HibanaGadget from './hibanaGadgetx6.png';
import HibanaGadgetx4 from './hibanaGadgetx4.png';
import HibanaGadgetx2 from './hibanaGadgetx2.png';
import IanaGadget from './ianaGadget.png';
import IqGadget from './iqGadget.png';
import JackalGadget from './jackalGadget.png';
import JagerGadget from './jagerGadget.png';
import KaidGadget from './kaidGadget.png';
import KaliGadget from './kaliGadget.png';
import KapkanGadget from './kapkanGadget.png';
import LesionGadget from './lesionGadget.png';
import LionGadget from './lionGadget.png';
import MaestroGadget from './maestroGadget.png';
import MaverickGadget from './maverickGadget.png';
import MelusiGadget from './melusiGadget.png';
import MiraGadget from './miraGadget.png';
import MontagneGadget from './montagneGadget.png';
import MozzieGadget from './mozzieGadget.png';
import MuteGadget from './muteGadget.png';
import NokkGadget from './nokkGadget.png';
import NomadGadget from './nomadGadget.png';
import OryxGadget from './oryxGadget.png';
import OsaGadget from './osaGadget.png';
import PulseGadget from './pulseGadget.png';
import RamGadget from './ramGadget.png';
import RookGadget from './rookGadget.png';
import SensGadget from './sensGadget.png';
import SledgeGadget from './sledgeGadget.png';
import SmokeGadget from './smokeGadget.png';
import SolisGadget from './solisGadget.png';
import TachankaGadget from './tachankaGadget.png';
import ThatcherGadget from './thatcherGadget.png';
import ThermiteGadget from './thermiteGadget.png';
import ThornGadget from './thornGadget.png';
import ThunderbirdGadget from './thunderbirdGadget.png';
import TubaraoGadget from './tubaraoGadget.png';
import TwitchGadget from './twitchGadget.png';
import ValkyrieGadget from './valkyrieGadget.png';
import VigilGadget from './vigilGadget.png';
import WamaiGadget from './wamaiGadget.png';
import WardenGadget from './wardenGadget.png';
import YingGadget from './yingGadget.png';
import ZeroGadget from './zeroGadget.png';
import ZofiaConcussionGadget from './zofiaConcussionGadget.png';
import ZofiaImpactGadget from './zofiaImpactGadget.png';
import SkoposTalosGadget from './talos.png';
import SkoposKolossosGadget from './kolossos.png';

// Secondary Utility Icon Imports
import SmokeGrenade from './smokeGrenade.png';
import Claymore from './claymore.png';
import ImpactEmp from './impactEmp.png';
import ImpactGrenade from './impactGrenade.png';
import NitroCell from './nitroCell.png';
import StunGrenade from './stunGrenade.png';
import BulletproofCamera from './bulletproofCamera.png';
import BarbedWire from './barbedWire.png';
import HardbreachTool from './hardbreachTool.png';
import BreachCharge from './breachCharge.png';
import ProximityAlarm from './proximityAlarm.png';
import FragGrenade from './fragGrenade.png';
import DeployableShield from './deployableShield.png';
import ObservationBlocker from './observationBlocker.png';

// Tertiary Utility Icon Imports
import GonneSix from './gonneSix.png';

// Misc Icons
import UnknownIcon from './unknown.png';
import Drone from './drone.png';
import RotateIcon from './rotate.png';
import BarricadeIcon from './barricade.png';
import Reinforcement from './reinforcement.png';
import Defuser from './defuser.png';
import SiteA from './site-a.png';
import SiteB from './site-b.png';

const icons = {
    AceIcon,
    AlibiIcon,
    AttackerIcon,
    AmaruIcon,
    AruniIcon,
    AshIcon,
    AzamiIcon,
    BanditIcon,
    BlackbeardIcon,
    BlitzIcon,
    BravaIcon,
    BuckIcon,
    CapitaoIcon,
    CastleIcon,
    CaveiraIcon,
    ClashIcon,
    DefenderIcon,
    DeimosIcon,
    DocIcon,
    DokkaebiIcon,
    EchoIcon,
    ElaIcon,
    FinkaIcon,
    FenrirIcon,
    FloresIcon,
    FrostIcon,
    FuzeIcon,
    GlazIcon,
    GoyoIcon,
    GridlockIcon,
    GrimIcon,
    HibanaIcon,
    IanaIcon,
    IqIcon,
    JackalIcon,
    JagerIcon,
    KaidIcon,
    KaliIcon,
    KapkanIcon,
    LesionIcon,
    LionIcon,
    MaestroIcon,
    MaverickIcon,
    MelusiIcon,
    MiraIcon,
    MontagneIcon,
    MozzieIcon,
    MuteIcon,
    NokkIcon,
    NomadIcon,
    ObservationBlocker,
    OryxIcon,
    OsaIcon,
    PulseIcon,
    BlueRecruitIcon,
    GreenRecruitIcon,
    OrangeRecruitIcon,
    RedRecruitIcon,
    YellowRecruitIcon,
    RamIcon,
    RookIcon,
    SensIcon,
    SledgeIcon,
    SmokeIcon,
    SolisIcon,
    TachankaIcon,
    ThatcherIcon,
    ThermiteIcon,
    ThornIcon,
    ThunderbirdIcon,
    TubaraoIcon,
    TwitchIcon,
    ValkyrieIcon,
    VigilIcon,
    WamaiIcon,
    WardenIcon,
    YingIcon,
    ZeroIcon,
    ZofiaIcon,
    AceGadget,
    AlibiGadget,
    AmaruGadget,
    AruniGadget,
    AshGadget,
    AzamiGadget,
    BanditGadget,
    BlackbeardGadget,
    BlitzGadget,
    BravaGadget,
    BuckGadget,
    CapitaoFireGadget,
    CapitaoSmokeGadget,
    CastleGadget,
    CaveiraGadget,
    ClashGadget,
    DeimosGadget,
    DocGadget,
    DokkaebiGadget,
    EchoGadget,
    ElaGadget,
    FinkaGadget,
    FenrirGadget,
    FloresGadget,
    FrostGadget,
    FuzeGadget,
    GlazGadget,
    GoyoGadget,
    GridlockGadget,
    GrimGadget,
    HibanaGadget,
    HibanaGadgetx4,
    HibanaGadgetx2,
    IanaGadget,
    IqGadget,
    JackalGadget,
    JagerGadget,
    KaidGadget,
    KaliGadget,
    KapkanGadget,
    LesionGadget,
    LionGadget,
    MaestroGadget,
    MaverickGadget,
    MelusiGadget,
    MiraGadget,
    MontagneGadget,
    MozzieGadget,
    MuteGadget,
    NokkGadget,
    NomadGadget,
    OryxGadget,
    OsaGadget,
    PulseGadget,
    RamGadget,
    RookGadget,
    SensGadget,
    SledgeGadget,
    SmokeGadget,
    SolisGadget,
    TachankaGadget,
    ThatcherGadget,
    ThermiteGadget,
    ThornGadget,
    TubaraoGadget,
    ThunderbirdGadget,
    TwitchGadget,
    ValkyrieGadget,
    VigilGadget,
    WamaiGadget,
    WardenGadget,
    YingGadget,
    ZeroGadget,
    ZofiaConcussionGadget,
    ZofiaImpactGadget,
    SmokeGrenade,
    Claymore,
    ImpactEmp,
    ImpactGrenade,
    NitroCell,
    StunGrenade,
    BulletproofCamera,
    BarbedWire,
    HardbreachTool,
    BreachCharge,
    ProximityAlarm,
    FragGrenade,
    DeployableShield,
    GonneSix,
    UnknownIcon,
    Drone,
    RotateIcon,
    BarricadeIcon,
    Reinforcement,
    StrikerIcon,
    SentryIcon,
    SkoposIcon,
    SkoposKolossosGadget,
    SkoposTalosGadget,
    Defuser,
    SiteA,
    SiteB,
};

export default icons;
export {
    AceIcon,
    AlibiIcon,
    AttackerIcon,
    AmaruIcon,
    AruniIcon,
    AshIcon,
    AzamiIcon,
    BanditIcon,
    BlackbeardIcon,
    BlitzIcon,
    BravaIcon,
    BuckIcon,
    CapitaoIcon,
    CastleIcon,
    CaveiraIcon,
    ClashIcon,
    DefenderIcon,
    DeimosIcon,
    DocIcon,
    DokkaebiIcon,
    EchoIcon,
    ElaIcon,
    FinkaIcon,
    FenrirIcon,
    FloresIcon,
    FrostIcon,
    FuzeIcon,
    GlazIcon,
    GoyoIcon,
    GridlockIcon,
    GrimIcon,
    HibanaIcon,
    IanaIcon,
    IqIcon,
    JackalIcon,
    JagerIcon,
    KaidIcon,
    KaliIcon,
    KapkanIcon,
    LesionIcon,
    LionIcon,
    MaestroIcon,
    MaverickIcon,
    MelusiIcon,
    MiraIcon,
    MontagneIcon,
    MozzieIcon,
    MuteIcon,
    NokkIcon,
    NomadIcon,
    ObservationBlocker,
    OryxIcon,
    OsaIcon,
    PulseIcon,
    BlueRecruitIcon,
    GreenRecruitIcon,
    OrangeRecruitIcon,
    RedRecruitIcon,
    YellowRecruitIcon,
    RamIcon,
    RookIcon,
    SensIcon,
    SledgeIcon,
    SmokeIcon,
    SolisIcon,
    TachankaIcon,
    ThatcherIcon,
    ThermiteIcon,
    ThunderbirdIcon,
    TubaraoIcon,
    TwitchIcon,
    ValkyrieIcon,
    VigilIcon,
    WamaiIcon,
    WardenIcon,
    YingIcon,
    ZeroIcon,
    ZofiaIcon,
    AceGadget,
    AlibiGadget,
    AmaruGadget,
    AruniGadget,
    AshGadget,
    AzamiGadget,
    BanditGadget,
    BlackbeardGadget,
    BlitzGadget,
    BravaGadget,
    BuckGadget,
    CapitaoFireGadget,
    CapitaoSmokeGadget,
    CastleGadget,
    CaveiraGadget,
    ClashGadget,
    DeimosGadget,
    DocGadget,
    DokkaebiGadget,
    EchoGadget,
    ElaGadget,
    FinkaGadget,
    FenrirGadget,
    FloresGadget,
    FrostGadget,
    FuzeGadget,
    GlazGadget,
    GoyoGadget,
    GridlockGadget,
    GrimGadget,
    HibanaGadget,
    IanaGadget,
    IqGadget,
    JackalGadget,
    JagerGadget,
    KaidGadget,
    KapkanGadget,
    LesionGadget,
    LionGadget,
    MaestroGadget,
    MaverickGadget,
    MelusiGadget,
    MiraGadget,
    MozzieGadget,
    MuteGadget,
    NokkGadget,
    NomadGadget,
    OryxGadget,
    OsaGadget,
    PulseGadget,
    RamGadget,
    RookGadget,
    SensGadget,
    SledgeGadget,
    SmokeGadget,
    SolisGadget,
    TachankaGadget,
    ThatcherGadget,
    ThermiteGadget,
    ThunderbirdGadget,
    TubaraoGadget,
    TwitchGadget,
    ValkyrieGadget,
    VigilGadget,
    WamaiGadget,
    WardenGadget,
    YingGadget,
    ZeroGadget,
    ZofiaConcussionGadget,
    ZofiaImpactGadget,
    SmokeGrenade,
    Claymore,
    ImpactEmp,
    ImpactGrenade,
    NitroCell,
    StunGrenade,
    BulletproofCamera,
    BarbedWire,
    HardbreachTool,
    BreachCharge,
    ProximityAlarm,
    FragGrenade,
    DeployableShield,
    GonneSix,
    UnknownIcon,
    Drone,
    RotateIcon,
    BarricadeIcon,
    Reinforcement,
    StrikerIcon,
    SentryIcon,
    SkoposIcon,
    SkoposKolossosGadget,
    SkoposTalosGadget,
    SiteA,
    SiteB,
};
